import { useEffect, useState } from "react";
import Application from "./Application";
import { ApiKeyDetail } from "./Types";

const random = window.crypto.getRandomValues(new Uint32Array(2));
const nonce = random[0];
const state = random[1];

const url = "https://myob-api-admin.svc.platform.myobdev.com";
const apiKeyUrl = "/v1/api-key";

async function fetchApps() {
  try {
    const response = await fetch(apiKeyUrl);

    if (response.status === 200) {
      const json = await response.json();
      return json;
    }

    if (response.status === 401) {
      console.log("failed to authorise!");
      window.location.href = `${url}/login?nonce=${nonce}`;
      localStorage.setItem(
        "login.state",
        JSON.stringify({ [state]: { redirect: "some-trust-url" }, nonce })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

const ApplicationList = () => {
  const [apps, setApps] = useState<ApiKeyDetail[]>([]);

  useEffect(() => {
    async function fetchApp() {
      setApps(await fetchApps());
    }
    fetchApp();
  }, []);

  return (
    <>
      {apps ? (
        apps.map((app) => <Application key={app.id} {...app} />)
      ) : (
        <p>No applications!</p>
      )}
    </>
  );
};

export default ApplicationList;
