const Home = () => {
  return (
    <>
      <h1>Home</h1>
      <p>This page is public</p>
    </>
  );
};

export default Home;
