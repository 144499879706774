import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.css";
import Home from "./Home";
import ApplicationList from "./ApplicationList";

const Test = () => {
  return <p>hi</p>;
};

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/test-public">public</Link>
              </li>
              <li>
                <Link to="/application">Application</Link>
              </li>
            </ul>
          </nav>

          <Switch>
            <Route path="/application" component={ApplicationList} />
            <Route path="/test-public" component={Test} />
            <Route path="/" component={Home} />
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
