import type { ApiKeyDetail } from "./Types";

const Application = (app: ApiKeyDetail) => (
  <div className="application">
    <h3>{app.appName}</h3>
    <p>description: {app.description}</p>
    <p>usage plan: {app.usagePlan}</p>
    <p>id: {app.id}</p>
    <p>keyId: {app.keyId}</p>
    <p>username: {app.userName}</p>
  </div>
);

export default Application;
